import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";

// Component imports
import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
import BlogPost from "../components/blog/BlogPost";
import Sidebar from "../components/blog/Sidebar";
import Contact from "../components/common/Contact";
// Image imports

// CSS imports

export default props => {
  let items = props.data.blogItems.edges;
  let selectedId = null;
  if (props.location && props.location.state) {
    selectedId = props.location.state.itemId;
  }
  let selectedEdge = null;
  if (selectedId) {
    selectedEdge = _.find(items, item => {
      return item.node.id === selectedId;
    });
  }

  let selectedItem = null;
  if (!selectedEdge) {
    selectedItem = _.first(items).node;
  } else {
    selectedItem = selectedEdge.node;
  }

  return (
    <Layout>
      <SEO
        title="TalonStrike Software Blog"
        keywords={[`blog`, `talonstrike`]}
      />

      <div className="app-navbar-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-auto">
              <Sidebar items={items} />
            </div>
            <div className="col-lg-9">
              <BlogPost
                title={selectedItem.frontmatter.title}
                date={selectedItem.frontmatter.date}
                content={selectedItem.html}
                id={selectedItem.id}
              />
            </div>
          </div>
        </div>
      </div>
      <Contact
        title="make a comment"
        descTitle="Comment"
        descPlaceHolder="Enter your comment here..."
        identifier={`blog_${selectedItem.frontmatter.title}`}
      />
    </Layout>
  );
};

export const query = graphql`
  {
    blogItems: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/md/blog/*.md" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            path
          }
          id
          html
        }
      }
    }
  }
`;

import React from "react";
import { Link } from "gatsby";

export default class BlogIndexItem extends React.Component {
  render() {
    let truncateLength = 30;
    let item = this.props.item.frontmatter;
    let postDate = new Date(item.date);
    let title = item.title;
    if (title.length > truncateLength) {
      title = `${title.substring(0, truncateLength)}...`;
    }
    // const location = {
    //   pathname: "/blog",
    //   state: { fromDashboard: true }
    // };

    // NOTE: This is using GatsbyLink, not react-router Link, which has a 
    // different syntax.  You have been warned.
    //let link = `/blog?${this.props.item.id}`;
    return (
      <div className="mb-2">
        <Link to='/blog' state={{itemId: this.props.item.id }} className="h4 text-nowrap sidebar-item">
          {title}
        </Link>
        <br />
        <span className="text-muted">{postDate.toLocaleDateString()}</span>
      </div>
    );
  }
}

import React from 'react'

//Component imports
import BlogIndexItem from "./BlogIndexItem";

export default class Sidebar extends React.Component {

  render() {
    return (
      <div className="container text-light sidebar mt-3">
        {
          this.props.items.map(item => {
            return (
              <BlogIndexItem item={item.node} key={item.node.id}/>
            )
          })
        }
      </div>
    )
  }
}

import React from "react"
import Title from "./Title"

// import CSS
import '../../assets/sass/index.scss'
export default function Contact({ title = 'contact us', descTitle = 'Description', descPlaceHolder = 'your description goes here...', identifier='toplevel'}) {
    return (
      <div>
        <section className="contact py-5" id="contact">
          <Title title={title} />
          <div className="row">
            <div className="col-10 col-sm-8 col-md-6 mx-auto text-white">
              <form
                action="https://formspree.io/support@talonstrikesoftware.com"
                method="POST"
              >
                {/* name */}
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="john smith"
                  />
                </div>
                {/* email */}
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="email@email"
                  />
                </div>
                {/* email */}
                <div className="form-group">
                  <label htmlFor="description">{descTitle}</label>
                  <textarea
                    name="description"
                    id="description"
                    className="form-control"
                    rows="5"
                    placeholder={descPlaceHolder}
                  />
                </div>
                <input type="hidden" name="identifier" value={identifier}></input>
                {/* submit */}
                <button
                  id="contact_submit"
                  type="submit"
                  className="btn text-white btn-outline-light text-capitalize mt-1 btn-toolbar mx-auto"
                >
                  submit
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
}

import React from "react";

// Component imports

// CSS imports
import "../../assets/sass/blog.scss";

export default class BlogPost extends React.Component {
  render() {
    let postDate = new Date(this.props.date);
    return (
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 text-white">
            <div className="display-4">{this.props.title}</div>
            <span className="text-muted">{postDate.toLocaleDateString()}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className="text-white blogpost"
              dangerouslySetInnerHTML={{ __html: this.props.content }}
            />
          </div>
        </div>
      </div>
    );
  }
}
